import style from './SkeletonPlaceholder.module.scss'

interface SkeletonPlaceholderProps {
  className?: string,
}

const SkeletonPlaceholder: React.FC<SkeletonPlaceholderProps> = ({ className }) => {
  return <div className={`${style.wrapper}${className ? ' ' + className : ''}`}>
    <div className={style.activity} />
  </div>
}

export default SkeletonPlaceholder;