import { FieldError } from 'react-hook-form';
import style from './Input.module.scss'
import { forwardRef } from 'react';
import InputMask from 'react-input-mask';
import { classNames } from '../../../utils/classNames/classNames';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: FieldError,
  touched?: boolean | undefined,
  children?: React.ReactNode,
  id: string,
  mask?: string,
  sizeType?: 'small' | 'default'
}

const Input: React.FC<InputProps> = forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    error,
    touched,
    children,
    id,
    mask,
    className,
    sizeType = 'default',
    type = 'text',
    ...otherProps
  } = props;

  return <div className={style.wrapper}
    data-status={error ? 'error' : touched ? 'success' : ''}>
    {children && <label className={style.label} htmlFor={id}>
      {children}
    </label>}
    {mask ? <InputMask
      type={type}
      mask={mask}
      className={style.input}
      {...otherProps}
      inputRef={ref}
    />
      :
      <input id={id}
        type={type}
        data-size={sizeType}
        className={classNames(style.input, {}, [className ?? ''])}
        {...otherProps}
        ref={ref}
      />
    }
    {error?.message && <p className={style.helpBlock}>{error?.message}</p>}
  </div>
})

export default Input;