import SkeletonPlaceholder from '../../../../UI/SkeletonPlaceholder/SkeletonPlaceholder';
import style from './HomeAdItem.module.scss'

const HomeAdPlaceholder: React.FC = () => {
  return <div className={style.container}>
    <div className={style.main}>
      <SkeletonPlaceholder className={style.titlePlaceholder} />
      <div>
        <SkeletonPlaceholder className={style.shortPlaceholder} />
        <SkeletonPlaceholder className={style.shortPlaceholder} />
        <SkeletonPlaceholder className={style.linkPlaceholer} />
      </div>
    </div>
  </div>
}

export default HomeAdPlaceholder;