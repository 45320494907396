import React from "react";
import FooterEmailForm from "./FooterEmailForm/FooterEmailForm";
import style from './Footer.module.scss'
import { NavLink } from "react-router-dom";

const currentYear = new Date().getFullYear();

const Footer: React.FC = () => {
  return (
    <footer className={style.footer}>
      <div className={style.wrapper}>
        <div className={style.align}>
          <ul className={style.nav}>
            <li><NavLink to='/'>Главная</NavLink></li>
            <li><NavLink to='advertiser'>Рекламодателям</NavLink></li>
            <li><NavLink to='about'>О нас</NavLink></li>
            <li><NavLink to='contacts'>Контакты</NavLink></li>
          </ul>
          <FooterEmailForm />
        </div>
        <div className={style.copyAlign}>
          <p>Медиахолдинг Ассоциации «Галерея Чижова», <span>{currentYear}</span></p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;