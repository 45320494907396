import { useRef } from "react";
import style from './OurCompanySlider.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Pagination, Navigation, Autoplay } from "swiper";
import companyImg1 from '../../../../../assets/image/company/company-1.webp'
import companyImg2 from '../../../../../assets/image/company/company-2.webp'
import companyImg3 from '../../../../../assets/image/company/company-3.webp'
import companyImg4 from '../../../../../assets/image/company/company-4.webp'
import companyImg5 from '../../../../../assets/image/company/company-5.webp'
import { useState } from "react";
import SwiperArrow from "../../../../UI/SwiperArrow/SwiperArrow";
import { Swiper as SwiperType } from 'swiper/types';

const OurCompanySlider: React.FC = () => {
  const [swiper, setSwiper] = useState<SwiperType>();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  return <div className={style.sliderContainer}>
    <Swiper
      navigation={{
        prevEl: prevRef.current,
        nextEl: nextRef.current,
      }}
      slidesPerView={1}
      loop={true}
      speed={700}
      pagination={{
        clickable: true,
        bulletClass: 'customBullet',
        bulletActiveClass: 'activeCustomBullet',
        renderBullet: function (index, className) {
          return `<span class="${className}" style="width: ${100 / 5}%"></span>`;
        }
      }}
      autoplay={{
        delay: 30000,
        disableOnInteraction: false,
      }}
      onSwiper={setSwiper}
      modules={[Autoplay, Pagination, Navigation]}
      className={style.slider}
    >
      <SwiperSlide className={style.slide}>
        <img src={companyImg1} className={style.slideImg} loading="lazy" alt="Наша компания" />
      </SwiperSlide>
      <SwiperSlide className={style.slide}>
        <img src={companyImg2} className={style.slideImg} loading="lazy" alt="Наша компания" />
      </SwiperSlide>
      <SwiperSlide className={style.slide}>
        <img src={companyImg3} className={style.slideImg} loading="lazy" alt="Наша компания" />
      </SwiperSlide>
      <SwiperSlide className={style.slide}>
        <img src={companyImg4} className={style.slideImg} loading="lazy" alt="Наша компания" />
      </SwiperSlide>
      <SwiperSlide className={style.slide}>
        <img src={companyImg5} className={style.slideImg} loading="lazy" alt="Наша компания" />
      </SwiperSlide>
    </Swiper>
    <SwiperArrow direction="left" className={style.navigationPrev} ref={prevRef} />
    <SwiperArrow className={style.navigationNext} ref={nextRef} />
  </div>
}

export default OurCompanySlider;