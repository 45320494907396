import { classNames } from '../../../utils/classNames/classNames';
import style from './Title.module.scss'

export enum TitleSize {
  STANDART = 'standart',
  SMALL = 'small'
}

interface TitleProps {
  className?: string,
  children: React.ReactNode,
  size?: TitleSize
}

const Title: React.FC<TitleProps> = ({ children, className, size = TitleSize.STANDART }) => {
  const additionalClasses = [className ?? '', style[size]];
  
  return <h1 className={classNames(style.title, {}, additionalClasses)}>
    {children}
  </h1 >
}

export default Title;