import { useContext } from "react";
import { ModalActive } from "./types";
import { ModalContext } from "./ModalProvider";

interface useModalResult {
  modalActive: ModalActive
  openBriff: () => void,
  openCall: () => void,
  closeModal: () => void
}

export default function useModal(): useModalResult {
  const { modalActive, setModalActive } = useContext(ModalContext);

  const openBriff = (): void => {
    setModalActive('briff')
  }

  const openCall = (): void => {
    setModalActive('call')
  }

  const closeModal = (): void => {
    setModalActive(false)
  }

  return {
    modalActive,
    openBriff,
    openCall,
    closeModal
  }
}