import style from './CheckBox.module.scss'

interface CheckBoxProps {
  checked: boolean,
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  children?: React.ReactNode,
  id: string,
  required?: boolean,
  value?:string
}

const CheckBox: React.FC<CheckBoxProps> = ({ checked, onChange, children, id, value, required }) => {
  return <div className={style.wrapper}>
    <label className={style.label} htmlFor={id}>
      {children}
      <input type="checkbox" id={id} required={required} value={value}
        checked={checked} onChange={onChange} className={style.input} />
      <div className={style.switch} />
    </label>
  </div>
}

export default CheckBox;