import style from './ModalFormSucces.module.scss'
import { Link } from 'react-router-dom';
import SubmitButton from '../../UI/SubmitButton/SubmitButton';
import useModal from '../../../providers/ModalProvider/useModal';

const ModalFormSucces: React.FC = () => {
  const { closeModal } = useModal();

  return <div className={style.wrapper}>
    <h2 className={style.title}>Спасибо <br /> за заявку</h2>
    <p className={style.text}>Наш специалист свяжется с вами сразу же после обработки информации.</p>
    <Link to='/'>
      <SubmitButton
        className={style.submit}
        type='button'
        onClick={closeModal}>Вернуться на Главную</SubmitButton>
    </Link>
  </div>
}

export default ModalFormSucces;