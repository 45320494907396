import { useState } from 'react';
import style from './FooterEmailForm.module.scss'
import { sendForm } from '../../../../utils/api';
import { useForm } from 'react-hook-form';
import { IEmail } from '../../../../types/types';

const FooterEmailForm = () => {
  const [sub, setSub] = useState(false);
  const { register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm<IEmail>({ mode: 'onBlur' });

  const onSubmit = (data: IEmail) => {
    sendForm('sub', data);
    reset();
    setSub(true);
  }

  return (
    <div className={style.subscribe}>
      <form className={style.form}
        data-status={errors.email ? 'error' : touchedFields.email ? 'succes' : ''}
        onSubmit={handleSubmit(onSubmit)}>
        <h2>Оформить подписку</h2>
        <p>Подпишитесь, чтобы получать извещения о специальных предложениях</p>
        {
          sub ? <p>Вы подписались</p> :
            <>
              <input type="email" placeholder="Ваш email" className={style.input}
                {...register('email', {
                  required: true,
                  pattern: /\S+@\S+\.\S+/
                })} />
              <button className={style.submit} type="submit">Подписаться</button>
            </>
        }
      </form>
    </div>
  )
}

export default FooterEmailForm;