import { createContext, useMemo, useState } from "react"
import { IModal, ModalActive } from "./types"

export const ModalContext = createContext<IModal>({ modalActive: false, setModalActive: () => { } })

interface ModalProviderProps {
  children: React.ReactNode
}

const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalActive, setModalActive] = useState<ModalActive>(false)

  const defaultValue = useMemo(() => ({
    modalActive,
    setModalActive
  }), [modalActive])

  return <ModalContext.Provider value={defaultValue}>
    {children}
  </ModalContext.Provider>
}

export default ModalProvider;