import { useEffect } from "react";
import Microphone from "./Microphone/Microphone";
import OurCompany from "./OurCompany/OurCompany";
import SpecialOffers from "./SpecialOffers/SpecialOffers";
import { setDocumentTitle } from "../../../utils/setDocumentTitle/setDocumentTitle";
import HomeAdBlock from "./HomeAdBlock/HomeAdBlock";

const HomePage: React.FC = () => {
  useEffect(() => {
    setDocumentTitle('Медиахолдинг Галереи Чижова');
  }, [])

  return (
    <>
      <HomeAdBlock />
      <SpecialOffers />
      <OurCompany />
      <Microphone />
    </>
  )
}

export default HomePage;