import style from './Microphone.module.scss'
import FormButtons from '../../../share/FormButtons/FormButtons'
import micro from '../../../../assets/image/banner-microphone.webp'

const Microphone: React.FC = () => {
  return <div className={style.wrapper}>
    <img src={micro} alt="Микрофон" className={style.background} />
    <div className={style.buttons}>
      <FormButtons />
    </div>
  </div>
}

export default Microphone;