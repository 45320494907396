import { NavLink } from 'react-router-dom';
import style from './MobileMenu.module.scss'
import Portal from '../../../share/Portal/Portal';
import { classNames } from '../../../../utils/classNames/classNames';
import { linkType } from '../../../../types/types';

interface MobileMenuProps {
  active: boolean,
  onClose: () => void,
  links: linkType[]
}

const MobileMenu: React.FC<MobileMenuProps> = ({ active, onClose, links }) => {
  return <Portal>
    <div className={classNames(style.overlay, { [style.active]: active })} onClick={onClose}>
      <div className={style.menu} onClick={e => e.stopPropagation()}>
        <ul className={style.navigation}>
          {links.map(({ name, path }) => {
            return <li onClick={onClose} key={path}>
              <NavLink to={path}
                className={({ isActive }) => classNames(style.link,
                  { [style.activeLink]: isActive })}>
                {name}
              </NavLink>
            </li>
          })}
        </ul>
        <a href="tel:+74732619995" className={style.phone}>
          +7 (473) 261-99-95
        </a>
      </div>
    </div>
  </Portal >
}

export default MobileMenu;