import { useRef, useState } from "react";
import style from './SpecialOffers.module.scss'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import { Link } from "react-router-dom";
import SkeletonPlaceholder from '../../../UI/SkeletonPlaceholder/SkeletonPlaceholder'
import Container from "../../../UI/Container/Container";
// import SwiperArrow from "../../../UI/SwiperArrow/SwiperArrow";
import { AdsType } from "../../../../types/types";
import { useQuery } from "react-query";
import { BASE_URL } from "../../../../utils/api";
import { Swiper as SwiperType } from 'swiper/types';
import { isArrayFull } from "../../../../utils/isArrayFull/isArrayFull";

const SpecialOffers: React.FC = () => {
  const [, setSwiper] = useState<SwiperType>();
  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const { data, isLoading } = useQuery({
    queryKey: ['specialOffers'],
    queryFn: (): Promise<AdsType[]> => fetch(`${BASE_URL}api/media/offers`)
      .then(res => res.json()).then(res => res.models)
  })

  return <Container className={style.sliderContainer}>
    <h2 className={style.title}>Специальное предложение</h2>
    {!isLoading ?
      (isArrayFull(data) &&
        <Swiper
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSwiper={setSwiper}
          slidesPerView='auto'
          centeredSlides={true}
          /* loop={data?.length! > 1} */
          speed={700}
          /* pagination={{
            clickable: true,
            bulletClass: 'customBullet',
            bulletActiveClass: 'activeCustomBullet',
            renderBullet: function (index, className) {
              return `<span class="${className}" style="width: ${100 / data.length}%"></span>`;
            }
          }} */
          autoplay={{
            delay: 30000,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          className={style.specOffersSlider}
        >
          {data?.map(({ image, slug, title }, index) => {
            return (
              <SwiperSlide key={index} className={style.slide}>
                <Link to={`/offers/${slug}`}
                  className={style.slideLink} aria-label='Подробнее'>
                  <img className={style.slideImage} src={image.replace('@', BASE_URL)}
                    alt="Специальное предложение"
                    width='100%'
                    height='100%' />
                </Link>
                <Link to={`/offers/${slug}`}
                  className={style.slideTitle}>
                  {title}
                </Link>
              </SwiperSlide>
            )
          })}
        </Swiper>
      )
      : <>
        <SkeletonPlaceholder className={style.placeholderImage} />
        <SkeletonPlaceholder className={style.placeholderTitle} />
      </>}
  </Container>
}

export default SpecialOffers;