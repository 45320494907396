import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const root = document.getElementById('root');

interface scrollToTopProps {
  children: React.ReactNode
}

const ScrollToTop: React.FC<scrollToTopProps> = ({ children }) => {
  const location = useLocation();
  useEffect(() => {
    root?.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>
};

export default ScrollToTop;